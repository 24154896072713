

import { Dispatch } from "redux"
import axios from 'axios';
import globalStates from "../../utils/global";

const SESSION_STATUS_LOGGED_IN = globalStates && globalStates.SESSION_STATUS_LOGGED_IN;
const BASE_URL = globalStates && globalStates.BASE_URL;
const actionTypes = {
    loading: 'SESSIONS_LOADING',
    success: 'SESSIONS_SUCCESS',
    error: 'SESSIONS_ERROR'
}


const sessionsAction = ({reduxState, navigateCb, cb}:any) => async (dispatch:Dispatch) =>{
    try {
        console.log(">>>sessionsAction activated - reduxState", reduxState)
        //loading
        dispatch({
            type: actionTypes.loading,
            loading: true
        });

        // action
        const {SESSION_STATUS} = reduxState;
        const dispatchPayload = {
            SESSION_STATUS
        }

        console.log(">>>sessionsAction activated - dispatchPayload", dispatchPayload)
        //success
        dispatch({
            type: actionTypes.success,
            payload: dispatchPayload
        })
    } catch(error:any){
        //error 
        // console.log("error form action", error);
        const errorMessage = error && error.message 
        dispatch({
            type: actionTypes.error,
            message: errorMessage,
        })
    }
}

export default sessionsAction;