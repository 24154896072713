import { useContext, useEffect } from "react";
import globalStates from "../../utils/global"
import { PortalHomeScreen, PortalLoginScreen } from "../../containers";
import { AppNavbar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { sessionsAction } from "../../actions";

// import { HomeScreen1 } from "../../containers";
// import { AppNavbar } from "../../components";

const setTokenPerBrowser = (SESSION_STATUS: string, SESSION_STATUS_LOGGED_IN: string) =>{
    const isThere = localStorage.getItem('LOCAL_SESSION_STATUS') as string;
    if(isThere!==SESSION_STATUS_LOGGED_IN){
        localStorage.setItem('LOCAL_SESSION_STATUS', SESSION_STATUS);
    };
}
const getTokenPerBrowser = ()=>{
    const LOCAL_SESSION_STATUS = localStorage.getItem('LOCAL_SESSION_STATUS');
    return LOCAL_SESSION_STATUS;
}


const PortalLogin = () =>{
    const context = globalStates && globalStates.globalContext;
    
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const isLoggedIn = true;
    const SESSION_STATUS_LOGGED_IN = globalStates && globalStates.SESSION_STATUS_LOGGED_IN;

    const loginFormState = useSelector((state:any)=> state.loginForm);
    const loginFormStatePayload = loginFormState && loginFormState.payload;
    // const SESSION_STATUS = loginFormStatePayload && loginFormStatePayload.SESSION_STATUS

    const sessionsState = useSelector((state:any)=> state.sessions);
    const sessionsStatePayload = sessionsState && sessionsState.payload;
    const SESSION_STATUS = sessionsStatePayload && sessionsStatePayload.SESSION_STATUS;
    setTokenPerBrowser(SESSION_STATUS, SESSION_STATUS_LOGGED_IN); //<<< ENSURE THAT IN LOCAL WE HAVE SESSION_STATUS_LOGGED_IN;

    const dispatch = useDispatch();
    useEffect(()=>{
        console.log("use effect portal", SESSION_STATUS, {sessionsState})
        const LOCAL_SESSION_STATUS  = getTokenPerBrowser();
        console.log("use effect portal", {LOCAL_SESSION_STATUS})
     
        // this is to access session without login, so if local stored status == LOGGED IN, the session will be accessed;

        if((LOCAL_SESSION_STATUS===SESSION_STATUS_LOGGED_IN)){
            console.log(">>>logged in via local session status")
            const reduxState = {SESSION_STATUS: SESSION_STATUS_LOGGED_IN};
            dispatch(sessionsAction({reduxState}) as unknown as any);
        }

    }, [dispatch])
    

    return(
        <div className="portal-login-page">
            {
                SESSION_STATUS===SESSION_STATUS_LOGGED_IN ?
                <PortalHomeScreen/> :
                <PortalLoginScreen />
            }
        </div>  
    )
};

export default PortalLogin;