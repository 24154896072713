import { createContext } from "react";
import { BannerCoffee1 } from "../assets";

// portal dashboard menu

// use-context
const GlobalContext = createContext(null);

const ProductList = [
    {
        "_id": "66f11b30120bf983639c0c08",
        "productId": "gift-set-3",
        "product": "Gift Set 3",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "gift-set",
        "price": 57000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:28.920Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b2c120bf983639c0c05",
        "productId": "gift-set-2",
        "product": "Gift Set 2",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "gift-set",
        "price": 57000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:24.068Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b27120bf983639c0c02",
        "productId": "gift-set-1",
        "product": "Gift Set 1",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "gift-set",
        "price": 57000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:19.951Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-3",
        "product": "Manual Brew 3",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-4",
        "product": "Manual Brew 4",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-5",
        "product": "Manual Brew 5",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-6",
        "product": "Manual Brew 6",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-7",
        "product": "Manual Brew 7",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-8",
        "product": "Manual Brew 8",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-9",
        "product": "Manual Brew 9",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "manual-brew-10",
        "product": "Manual Brew 10",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "manual-brew",
        "price": 78590,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "custom-blend-beans-1",
        "product": "Custom Blend Beans 1",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "custom-blend-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "custom-blend-beans-2",
        "product": "Custom Blend Beans 2",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "custom-blend-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "custom-blend-beans-3",
        "product": "Custom Blend Beans 3",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "custom-blend-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "custom-blend-beans-4",
        "product": "Custom Blend Beans 4",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "custom-blend-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "ready-to-drink-1",
        "product": "Ready-to-Drink 1",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "ready-to-drink",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "ready-to-drink-2",
        "product": "Ready-to-Drink 2",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "ready-to-drink",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "ready-to-drink-3",
        "product": "Ready-to-Drink 3",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "ready-to-drink",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
    {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "specialty-beans-1",
        "product": "Specialty Beans 1",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "specialty-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
     {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "specialty-beans-2",
        "product": "Specialty Beans 2",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "specialty-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    },
     {
        "_id": "66f11b17120bf983639c0bff",
        "productId": "specialty-beans-3",
        "product": "Specialty Beans 3",
        "status": "available",
        // "image": "https://image.png",
        image: {
            src: BannerCoffee1,
            alt: ""
        },
        "store": "Q24WD0608",
        "group": "specialty-beans",
        "price": 99000,
        "currency": "IDR",
        "createdDate": "2024-09-23T07:39:03.542Z",
        "description": "Indulge in the rich, bold flavor of our freshly brewed coffee, crafted from handpicked beans sourced from the finest farms.",
        "__v": 0
    }

]

const TransactionList = [
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM002",
            "item": "Product C",
            "quantity": 3,
            "unitPrice": 100.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "payment pending",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "in progress",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM002",
            "item": "Product C",
            "quantity": 3,
            "unitPrice": 100.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM002",
            "item": "Product C",
            "quantity": 3,
            "unitPrice": 100.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "on delivery",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "in progress",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "in progress",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "payment pending",
        "createdDate": "20 Sep 2024",
    },
    {
        "orderId": "ORD120719",
        "transactionId": "TXN120719",
        "items": [
            {
                
            "itemId": "ITEM001",
            "item": "Product A",
            "quantity": 3,
            "unitPrice": 90.0,
            "currency": "IDR"
            },
            {
                
            "itemId": "ITEM003",
            "item": "Product B",
            "quantity": 1,
            "unitPrice": 30.0,
            "currency": "IDR"
            }
        ],
        "totalPrice": 78900.0,
        "currency": "IDR",
        "createdBy": "user123",
        "store": "Q24WD0608",
        "status": "order delivered",
        "createdDate": "20 Sep 2024",
    }
    
]

const globalStates =  {
    globalContext: GlobalContext,
    BASE_URL:("https://lr21oy9um7.execute-api.ap-southeast-3.amazonaws.com/dev" || "http://localhost:5003"),
    SESSION_STATUS_LOGGED_IN: "SESSION_STATUS_LOGGED_IN",
    ProductList,
    TransactionList
};

export default globalStates as any;
