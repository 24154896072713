
import { ComingSoon } from "../../containers";
import { AppNavbar } from "../../components";
// import { HomeScreen1 } from "../../containers";
// import { AppNavbar } from "../../components";

const Blog = () =>{
    
    return(
        <div>
            <AppNavbar/>
            <ComingSoon title={"Blog"}/>
        </div>
    )
};

export default Blog;