import "./PortalTscEditScreen.css";
import globalStates from "../../utils/global";
import { useContext } from "react";
import { IconPortalClose, IconPortalDelete } from "../../assets";

const PortalTscEditScreen = ({parentState}:any) =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const editModal = parentState && parentState.editModal;
    const setEditModal = parentState && parentState.setEditModal;

    const {
        status
    } = parentState;
    

    return(
        <div className={`${windowWidthClass}-portal-tsc-edit-screen`}> 
            <div className="modal"
            >
                <div className="icons">
                    <div className="frame">
                        <img 
                            src={IconPortalDelete}
                            alt=""
                            // onClick={()=>setEditModal(false)}
                        />
                    </div>
                    <div className="frame">
                        <img 
                            src={IconPortalClose}
                            alt=""
                            onClick={()=>setEditModal(false)}
                        />
                    </div>
                </div>
                <div className="detail row">
                    <p>Status</p>
                    <input value={status} />
                </div>
                <div className="buttons">
                    <p onClick={()=>setEditModal(false)}>save</p>
                </div>
            </div>
        </div>  
    )
};

export default PortalTscEditScreen;