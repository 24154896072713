

import { Dispatch } from "redux"
import axios from 'axios';
import globalStates from "../../utils/global";
import sessionsAction from "../sessionsAction";

const SESSION_STATUS_LOGGED_IN = globalStates && globalStates.SESSION_STATUS_LOGGED_IN;
const BASE_URL = globalStates && globalStates.BASE_URL;

const actionTypes = {
    loading: 'LOGIN_FORM_LOADING',
    success: 'LOGIN_FORM_SUCCESS',
    error: 'LOGIN_FORM_ERROR'
}

const postLoginFormAction = async ({URL, requestBody, dispatch, cb}:any) =>{
     try {
        const response = await axios.post(URL, requestBody);
         // If the response is successful
            if (response.status === 200) {
                console.log('Login successful', response.data);
                // Handle successful login (e.g., save token, redirect, etc.)
            } else {
                console.error('Login failed', response.statusText);
                // Handle failed login here
            }
        cb();
        // console.log('Success:', response.data);
        return response
    } catch (error:any) {
        // console.log('Error form:', error);
        const errorResponse =( error && error.response) || {};
        const errorData = (errorResponse && errorResponse.data) || {};
        const errorDataMessage =( errorData && errorData.message)|| {};
        const errorDataMessageValue ="Pastikan semua data sudah terisi" ||  (errorDataMessage && errorDataMessage.message) || "error form unknown";
        // console.log('Error form message:', errorDataMessageValue)
        throw new Error(errorDataMessageValue)
        // const errorMessage = error && error.message 
        // dispatch({
        //     type: actionTypes.error,
        //     message: errorMessage,
        // })
    }
};

const loginFormActionAction = ({data, method, cb}:any) => async (dispatch:Dispatch) =>{
    try {
        //loading
        dispatch({
            type: actionTypes.loading,
            loading: true
        });

        // action
        let response:any = {};
        if(method==="POST"){
            console.log(">>>posting to", BASE_URL)
            const requestBody = data || {};
            const URL = `${BASE_URL}/login/auth`
            response = await postLoginFormAction({URL, requestBody, dispatch, cb});
        }

        const dispatchPayload = {
            response,
            SESSION_STATUS: SESSION_STATUS_LOGGED_IN
        }

        //success
        dispatch({
            type: actionTypes.success,
            payload: dispatchPayload
        })
        if(dispatchPayload.SESSION_STATUS){
            
            const reduxState = {SESSION_STATUS: SESSION_STATUS_LOGGED_IN};
            console.log("sessionAction dispacted via login form", {reduxState})
            dispatch(sessionsAction({reduxState}) as unknown as any);
        }
    } catch(error:any){
        //error 
        // console.log("error form action", error);
        const errorMessage = error && error.message 
        dispatch({
            type: actionTypes.error,
            message: errorMessage,
        })
    }
}

export default loginFormActionAction;