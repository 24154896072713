import "./PortalProductsEditScreen.css";
import globalStates from "../../utils/global";
import { useContext } from "react";
import { IconPortalClose, IconPortalDelete } from "../../assets";

const PortalProductsEditScreen = ({parentState}:any) =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const editModal = parentState && parentState.editModal;
    const setEditModal = parentState && parentState.setEditModal;

    const {
        product,
        price, 
        productId,
        image,
        description
    } = parentState;
    

    return(
        <div className={`${windowWidthClass}-portal-products-edit-screen`}> 
            <div className="modal"
            >
                <div className="icons">
                    <div className="frame">
                        <img 
                            src={IconPortalDelete}
                            alt=""
                            // onClick={()=>setEditModal(false)}
                        />
                    </div>
                    <div className="frame">
                        <img 
                            src={IconPortalClose}
                            alt=""
                            onClick={()=>setEditModal(false)}
                        />
                    </div>
                </div>
                <div className="detail row">
                    <p>Product</p>
                    <input value={product} />
                </div>

                <div className="detail row">
                    <p>Price</p>
                    <input value={price} />
                </div>

                <div className="detail row">
                    <p>Image</p>
                    <input value={image} />
                </div>

                <div className="detail row">
                    <p>Description</p>
                    <input value={description} />
                </div>

                <div className="buttons">
                    <p
                         onClick={()=>setEditModal(false)}
                    >save</p>
                </div>
            </div>
        </div>  
    )
};

export default PortalProductsEditScreen;