import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../utils/global";
import "./PortalProductsScreen.css";
import { IconPortalAdd, IconPortalBack, IconPortalBasket, IconPortalCircleAdd, IconPortalCircleMinus, IconPortalThreedots } from "../../assets";
import axios from "axios";
import { current, UnknownAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginFormAction, sessionsAction } from "../../actions";
import PortalProductsEditScreen from "../PortalProductsEditScreen";

const filterProducts = (products:any, field:any, pattern:any) => {
  const regex = new RegExp(pattern, 'i'); // 'i' flag for case-insensitive matching
  return products.filter((product:any) => regex.test(product[field]));
};

const removeTokenPerBrowser = (SESSION_STATUS_ITEM:string) =>{
    // const isThere = localStorage.getItem(SESSION_STATUS_ITEM) as string;
    localStorage.removeItem(SESSION_STATUS_ITEM);
}

const filterNaBasketItems=(basketItems:any)=>{
        const items = basketItems && basketItems;
        const itemsList = Object.values(items);
        const filterItemsList = itemsList.filter((item:any)=> item.quantity > 0);
        return (filterItemsList || []);
    }

const getArrayPortion = (arr:any, nth1:any, nth2:any) => {
    // Check if input indices are valid and within the array's bounds
    if (nth1 < 0 || nth2 >= arr.length || nth1 > nth2) {
        console.error("Invalid indices");
        return [];
    }

    // Use slice to return the portion of the array
    return arr.slice(nth1, nth2 + 1);  // Adding 1 because slice is exclusive for the end index
};

function sumTotalPrice(items:any) {
    return items.reduce((sum:any, item:any) => {
        return sum + item.totalPrice;
    }, 0); // 0 is the initial value of the sum
}


const PortaProductsScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ProductList = globalStates && globalStates.ProductList;

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const screenHome1Ref = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenHome1Ref.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formData = {
            username,
            password
        }
        console.log({formData})

        try {
            dispatch(loginFormAction({
                data: formData,
                method: "POST",
                cb: ()=>navigate("/portal")
            }) as unknown as UnknownAction);

            // const response = await axios.post('http://localhost:5003/login/auth', requestBody, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });

            // // If the response is successful
            // if (response.status === 200) {
            //     console.log('Login successful', response.data);
            //     // Handle successful login (e.g., save token, redirect, etc.)
            // } else {
            //     console.error('Login failed', response.statusText);
            //     // Handle failed login here
            // }
        } catch (error) {
            console.error('Error during login', error);
            // Handle error during request
        }
        
        setUsername("")
        setPassword("")
    };


    const [inputBarGroup, setInputBarGroup] = useState("All Products");
    const [isInputBarGroupCollapsed, setInputBarGroupCollapsed ] = useState(false);
    const [inputBarValue, setInputBarValue] = useState("")
    const [productList, setProductList] = useState([]);

    const [isBasketDetails, setisBasketDetails] = useState(false);

    const [countBasket, setCountBasket] = useState(0);
    const [countAllBasket, setCountAllBasket] = useState({} as any);
    const [basketItems, setBasketItems] = useState({orderId: "001", transactionStatus: "001", items: {}, itemsList: []} as any);
    const [basketItemsList, setBasketItemsList] = useState(basketItems);

    const [editModal, setEditModal] = useState(false);
    // const [sumItemPrice, setSumItemPrice] = useState(0);

    const [dynamicHeightCarholder, setDynamicsHeightCardholder] = useState(60);

    const componentVariables ={
        title: "Beluga Coffee",
        decks: productList
    };
    
   
    // setProductList

    const loginFormState = useSelector((state:any)=> state.loginForm);
    // const loginFormStatePayload = loginFormState && loginFormState.payload;
    const loginFormStateLoading = loginFormState && loginFormState.loading;
    const loginFormStateError = loginFormState && loginFormState.error;
    const loginFormStateMessage = loginFormState && loginFormState.message;
    
    useEffect(()=>{
        setProductList(ProductList);
        // const portionArray = getArrayPortion(ProductList, 0, 7);
        // setProductList(portionArray);
        if (isBasketDetails) {
        document.body.classList.add('no-scroll'); // Disable scroll on body
        } else {
        document.body.classList.remove('no-scroll'); // Enable scroll on body
        }
        // Clean up when the component unmounts or state changes
        return () => {
        document.body.classList.remove('no-scroll');
        };
    }, [dispatch, loginFormState, isBasketDetails]);


    // const handleLogout = ()=>{
    //     removeTokenPerBrowser("LOCAL_SESSION_STATUS");
    //     const reduxState = {SESSION_STATUS: "SESSION_STATUS_LOGGED_OUT"};
    //     dispatch(sessionsAction({reduxState}) as unknown as any);
    // }

    const handleBack = () =>{
        navigate('/portal')
    }
    const handleInputChange =(e:any)=>{
        setInputBarValue("");
        if(e){
            const changes = e.target.value;
            setInputBarValue(changes);
        }
    }
    const handleSearch=(pattern:any)=>{
        const productGroupDictionary={
            "All Products": "",
            "Custom Blend Beans": "custom-blend-beans",
            "Specialty Beans": "specialty-beans",
            "Ready-to-Drink": "ready-to-drink",
            "Manual Brew": "manual-brew",
            "Gift Set": "gift-set"
        } as any;
        const filteredBarGroup = filterProducts(ProductList, 'group', productGroupDictionary[inputBarGroup]);

        const filtered = filterProducts(filteredBarGroup, 'product', pattern);
        setProductList(filtered);
    }
    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {
        // Perform action when Enter is pressed
        console.log('Enter key pressed! Input value:', inputBarValue);
        handleSearch(inputBarValue)
        } 
    };
    const handleInputGroupCollapsed=()=>{
        setInputBarGroupCollapsed(!isInputBarGroupCollapsed);
    }


    const handleInputGroupSubMenu=(inputBarGroup:string)=>{
        const productGroupDictionary={
            "All Products": "",
            "Custom Blend Beans": "custom-blend-beans",
            "Specialty Beans": "specialty-beans",
            "Ready-to-Drink": "ready-to-drink",
            "Manual Brew": "manual-brew",
            "Gift Set": "gift-set"
        } as any;
        setInputBarGroup(inputBarGroup);
        setInputBarGroupCollapsed(!isInputBarGroupCollapsed);
        const filtered = filterProducts(ProductList, 'group', productGroupDictionary[inputBarGroup])
        setProductList(filtered);
        
    }
    const handleBasketDetails=()=>{
        setisBasketDetails(!isBasketDetails)
    }

    const handleUpdateBasketItem = (item: any)=>{

        const currentItems = basketItems && basketItems.items;
        const currentProductId = item && item.productId;
        // const currentItem = currentItems[currentProductId];

        setBasketItems((prev:any)=>{
            // const currentItems = prev && prev.items;
            // const newItem = item
            // if(item){
            //     currentItems.push(item)
            // }
            const currentItems =  (prev && prev.items);

            const currentItemsKey = Object.keys(currentItems);
            const currentItemsList = Object.values(currentItems) || [];
            console.log(">>>> update basket", item.productId, currentItemsKey, currentItemsKey.includes(item.productId))
            let filteredCurrentItem = []
            if(item.quantity<1){
                filteredCurrentItem = currentItemsKey.includes(item.productId) ? currentItemsList.filter((subitem:any)=>subitem.productId!==item.productId) : [...currentItemsList];
            } else {
                filteredCurrentItem = currentItemsKey.includes(item.productId) ? currentItemsList.filter((subitem:any)=>subitem.quantity>0) : [...currentItemsList, item];
            }
            

            return (
                {
                    ...prev,
                    items: {
                        ...currentItems,
                        [currentProductId]: item
                    },
                    itemsList: filteredCurrentItem
                }
            )
        })
    }





    const handleMinusBasket =(productId:string, currentCount:any, basketItem:any)=>{
        if(countBasket>1){
            setCountBasket(countBasket-1)
        } else {
            setCountBasket(0)
        }

        // const prevCount = countAllBasket && countAllBasket[productId];
        const prevCount = currentCount
        console.log(">>> prevCount minus", prevCount)
         if(prevCount>1){
            setCountAllBasket((prev:any)=>{
                console.log("prev minus > 1", {prev});
                return {
                    ...prev,
                    [productId]: prevCount - 1
                }
            })
        } else {
            setCountAllBasket((prev:any)=>{
                console.log("prev minus else", {prev});
                 return {
                    ...prev,
                    [productId]: 0
                }
            })
        };

        handleUpdateBasketItem(basketItem)
    }
    
    const handleAddBasket =(productId: string, currentCount: any, basketItem:any)=>{
        if(countBasket>=0){
            setCountBasket(countBasket+1)
        } else {
            setCountBasket(0)
        };

        const prevCount =  currentCount;
        console.log(">>> prevCount add", prevCount, productId)
         if(prevCount>=0){
            setCountAllBasket((prev:any)=>{
                console.log("prev add  > 0 ", {prev})
                 return {
                    ...prev,
                    [productId]: prevCount + 1
                }

            })
        } else {
            setCountAllBasket((prev:any)=>{
                console.log("prev add else", {prev})
                 return {
                    ...prev,
                    [productId]: 0
                }
            })
        };

        handleUpdateBasketItem(basketItem)
    }

    

    

    useEffect(()=>{
        console.log(">>>>", {countAllBasket})
        console.log(">>>> basketItems", {basketItems})
        const basketItemsItems = basketItems && basketItems.items;
        setBasketItemsList(filterNaBasketItems(basketItemsItems));
    }, [countAllBasket, basketItems]);
    useEffect(()=>{
        console.log(">>>>basketItemsListStrict", basketItemsList);
    }, [basketItemsList])

    return(
        <div ref={screenHome1Ref} className={`${windowWidthClass}-portal-products-screen`}>
              <div className="navbar">
                    <div className="box bar-1">
                        
                    </div>
                    <div className="box bar-2">
                        
                    </div>
                    <div className="box bar-3">
                        <div className="frame">
                            <img 
                                src={IconPortalBack}
                                alt=""
                                onClick={()=>handleBack()}
                            />
                        </div>
                    </div>
                </div>
           
            <div className="wrapper display">

                <div className="actions bar">
                    <div className="action title">
                        <h1>{componentVariables.title}</h1>
                    </div>
                    <div className="action search">
                        <div className="input-wrapper">
                            <p className="input-wrapper product-group"
                                onClick={()=>handleInputGroupCollapsed()}
                            >
                               {inputBarGroup}
                            </p>
                            {
                                isInputBarGroupCollapsed ?
                                
                                <div className={`floating product-group`}>
                                    <p className="group title set1">Products</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("All Products")}
                                    >All Products</p>
                                    <p className="group title set2"> Subscription</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("Custom Blend Beans")}
                                    >Custom Blend Beans</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("Specialty Beans")}
                                    >Specialty Beans</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("Ready-to-Drink")}
                                    >Ready-to-Drink</p>
                                    <p className="group title set2"> Equipment</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("Manual Brew")}
                                    >Manual Brew</p>
                                    <p className="group anchor"
                                        onClick={()=>handleInputGroupSubMenu("Gift Set")}
                                    >Gift Set</p>
                                </div> : <></>
                            }
                            
                            <input 
                                placeholder="search coffee"
                                value={inputBarValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                            />
                            <p className="input-wrapper search"
                                onClick={()=>handleSearch(inputBarValue)}
                            >search</p>
                        </div>
                        
                    </div>
                    

                    {
                        ((windowWidthClass==="w-mob" )|| (windowWidthClass==="w-tab")) ? 
                        <div className="action icons bar">
                             <div className="action admin add">
                                <div className="frame icon">
                                    <img 
                                        src={IconPortalAdd}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="action admin add basket">
                                <div className="frame icon basket"
                                    onClick={handleBasketDetails}
                                    >
                                    <img 
                                        src={IconPortalBasket}
                                        alt=""
                                        
                                    />
                                    <div className="basket count">
                                        <p>{basketItemsList.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div> : 
                        <>
                            <div className="action admin add">
                                <div className="frame icon">
                                    <img 
                                        src={IconPortalAdd}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="action admin add basket">
                                <div className="frame icon basket"
                                    onClick={handleBasketDetails}
                                    >
                                    <img 
                                        src={IconPortalBasket}
                                        alt=""
                                        
                                    />
                                    <div className="basket count">
                                        <p>{basketItemsList.length}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

               <div className="cardholder products"
                style={
                    {height: `${dynamicHeightCarholder}vh`}
                }
               >
                    {
                        productList.map((item:any, index:any)=>{
                            const { 
                                product,
                                price, 
                                productId,
                                image,
                                description
                            } = item;

                            const src = image && image.src || "";
                            const alt = image && image.at || "";

                            const currentBasketCount = (countAllBasket && countAllBasket[productId]) || 0;

                            const basketItemAdd = {
                                productId,
                                product,
                                quantity: currentBasketCount + 1,
                                price: price,
                                totalPrice: (price * (currentBasketCount + 1)),
                                currency: "IDR",
                            }
                            const basketItemMinus = {
                                productId,
                                product,
                                quantity: currentBasketCount - 1,
                                price: price,
                                totalPrice: (price * (currentBasketCount - 1)),
                                currency: "IDR",
                            }

                            const parentState = {
                                editModal: editModal,
                                setEditModal: setEditModal,
                                product,
                                price, 
                                productId,
                                image,
                                description

                            }

                            return ( 
                                <div className="card product">
                                    <div className="object-wrapper"
                                    >
                                        <div className="icons">
                                            <div className="frame">
                                                    <img
                                                        src ={IconPortalThreedots}
                                                        alt =""
                                                        onClick={()=>setEditModal(true)}
                                                    />
                                            </div>
                                            {
                                                editModal ? <PortalProductsEditScreen parentState={parentState} /> : <></>
                                            }
                                        </div>
                                        <div className="images">
                                            <div className="frame">
                                                <img 
                                                    src={src}
                                                    alt={alt}
                                                />
                                            </div>
                                        </div>
                                        <div className="texts">
                                            <h3>{product}</h3>
                                            <p>IDR {price}.00</p>
                                            <p className="description">{description}</p>
                                            <div className="object-wrapper plus-minus">
                                                <div className="frame">
                                                    <img
                                                        src={IconPortalCircleMinus}
                                                        alt=""
                                                        onClick={()=>handleMinusBasket(productId, currentBasketCount, basketItemMinus)}
                                                    />
                                                </div>
                                                 <div className="frame">
                                                    {/* <img
                                                        src={IconPortalCircleAdd}
                                                        alt=""
                                                    /> */}
                                                    <p>
                                                        {currentBasketCount}
                                                    </p>
                                                </div>
                                                 <div className="frame">
                                                    <img
                                                        src={IconPortalCircleAdd}
                                                        alt=""
                                                        onClick={()=>handleAddBasket(productId, currentBasketCount, basketItemAdd)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
               </div>
                
                {
                    isBasketDetails ?
                    <div className="details baskets">
                        <h1>Order ID: {basketItems.orderId}</h1>
                        <div className="wrapper details">
                            
                            <div className="basket item card title">
                                <div className="basket card span">
                                    <p className="title product">Product</p>
                                    <p className="title quantity">Quantity</p>
                                    <p className="title title price">Total Price</p>
                                </div>
                            </div>
                            
                           {
                            basketItemsList.map((basket:any, index:any)=>{
                                const {
                                    product,
                                    totalPrice,
                                    quantity
                                } = basket;
                                return (
                                    <div className="basket item card">
                                        <div className="basket card span">
                                            <p className="product" >{product}</p>
                                            <p className="quantity">{quantity}</p>
                                            <p className="price">{totalPrice}</p>
                                        </div>
                                    </div>
                                )
                            })
                           }

                           <div className="basket item additionals">
                                <div className="row">
                                    <div className="span">
                                        <p className="addition empty"></p>
                                        <p className="addition delivery-cost">Delivery Cost</p>
                                        <p className="addition delivery-cost-value">15000</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="span">
                                        <p className="addition empty"></p>
                                        <p className="addition total-price">Total Price</p>
                                        <p className="addition total-price-value">{(sumTotalPrice(basketItemsList)+15000)}</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="button" onClick={()=>setisBasketDetails(false)}>
                                <p>check out</p>
                            </div>


                        </div>  
                    </div>  :
                    <></> 
                }
               

            </div>

        </div>
    )
};

export default PortaProductsScreen;