import "./Loader1.css";

const Loader1 = () =>{
    return (
        <div className="component loading">
            <span className="loader"></span>
        </div>
    )
};

export default Loader1;

