import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../utils/global";
import "./PortalHomeScreen.css";
import { AppLogo1, BannerCoffee1, BannerCoffee2, IconPortalClients, IconPortalLogout, IconPortalProducts, IconPortalProfiles, IconPortalTransactions } from "../../assets";
import axios from "axios";
import { UnknownAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginFormAction, sessionsAction } from "../../actions";

const removeTokenPerBrowser = (SESSION_STATUS_ITEM:string) =>{
    // const isThere = localStorage.getItem(SESSION_STATUS_ITEM) as string;
    localStorage.removeItem(SESSION_STATUS_ITEM);
}

const PortalHomeScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const screenHome1Ref = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenHome1Ref.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formData = {
            username,
            password
        }
        console.log({formData})

        try {
            dispatch(loginFormAction({
                data: formData,
                method: "POST",
                cb: ()=>navigate("/portal")
            }) as unknown as UnknownAction);

            // const response = await axios.post('http://localhost:5003/login/auth', requestBody, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });

            // // If the response is successful
            // if (response.status === 200) {
            //     console.log('Login successful', response.data);
            //     // Handle successful login (e.g., save token, redirect, etc.)
            // } else {
            //     console.error('Login failed', response.statusText);
            //     // Handle failed login here
            // }
        } catch (error) {
            console.error('Error during login', error);
            // Handle error during request
        }
        
        setUsername("")
        setPassword("")
    };

    const componentVariables ={
        title: "Beluga Coffee",
        decks: [
            {
                title: "Products",
                path: "products",
                desc: "Products inventory and managements",
                image: {
                    alt: "",
                    src: IconPortalProducts
                },
                id: 1
            },
            {
                title: "Transactions",
                path: "transactions",
                desc: "Transactions informations",
                image: {
                    alt: "",
                    src: IconPortalTransactions
                },
                id: 2
            },
            {
                title: "Clients",
                path: "clients",
                desc: "Client database and management",
                image: {
                    alt: "",
                    src: IconPortalClients
                },
                id: 3
            },
            {
                title: "Profile",
                path: "profile",
                desc: "User profile information and settings",
                image: {
                    alt: "",
                    src: IconPortalProfiles
                },
                id: 4,
                
            }
            
        ]
    }

    const loginFormState = useSelector((state:any)=> state.loginForm);
    // const loginFormStatePayload = loginFormState && loginFormState.payload;
    const loginFormStateLoading = loginFormState && loginFormState.loading;
    const loginFormStateError = loginFormState && loginFormState.error;
    const loginFormStateMessage = loginFormState && loginFormState.message;
    
    useEffect(()=>{
    //    console.log({requirementFormState});
    }, [dispatch, loginFormState])


    const handleLogout = ()=>{
        removeTokenPerBrowser("LOCAL_SESSION_STATUS");
        const reduxState = {SESSION_STATUS: "SESSION_STATUS_LOGGED_OUT"};
        dispatch(sessionsAction({reduxState}) as unknown as any);
    }
    return(
        <div ref={screenHome1Ref} className={`${windowWidthClass}-portal-home-screen`}>
              <div className="navbar">
                    <div className="box bar-1">
                        
                    </div>
                    <div className="box bar-2">
                        
                    </div>
                    <div className="box bar-3">
                        <div className="frame">
                            <img 
                                src={IconPortalLogout}
                                alt=""
                                onClick={()=>handleLogout()}
                            />
                        </div>
                    </div>
                </div>
            <div className="wrapper display">
               {/* <h1>{componentVariables.title}</h1> */}
               <div className="frame logo">
                    <img src={AppLogo1} alt="" />
               </div>
               <div className="cardholder dashboard-menu">
                    {
                         componentVariables.decks.map((item:any, index:any)=>{

                            const {title, desc, image} = item;

                            return (
                                <div className="card dashboard-menu">
                                    <div className="object-wrapper"
                                        onClick={()=>navigate(`/${title && title.toLowerCase()}`)}
                                    >
                                        <div className="images">
                                            <div className="frame">
                                                <img src={image.src} alt={image.alt}/>
                                                {/* <p>texts</p> */}
                                            </div>
                                        </div>
                                        <div className="texts">
                                            <h2>{title}</h2>
                                            <p>{desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                         })
                    }
               </div>
            </div>
        </div>
    )
};

export default PortalHomeScreen;