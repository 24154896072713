import { configureStore ,} from "@reduxjs/toolkit";
import {thunk} from "redux-thunk";
import { loginFormReducer, sessionsReducer } from "../reducers";

const store = configureStore({
  reducer: {
    loginForm: loginFormReducer,
    sessions: sessionsReducer
  },
  middleware: ()=>[thunk ]as any
});

export default store;
