
import { ComingSoon} from "../../containers";
import { AppNavbar } from "../../components";
// import { HomeScreen1 } from "../../containers";
// import { AppNavbar } from "../../components";

const ExperienceStore = () =>{
    return(
        <div>
            <AppNavbar/>
            <ComingSoon title={"Experience Store"}/>
        </div>
    )
};

export default ExperienceStore;